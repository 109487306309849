import { default as advertisersY5zffW22E9Meta } from "/opt/buildhome/repo/pages/advertisers.vue?macro=true";
import { default as analyticsFiKBOYLNmQMeta } from "/opt/buildhome/repo/pages/analytics.vue?macro=true";
import { default as authdjl0VO0QO1Meta } from "/opt/buildhome/repo/pages/auth.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as publishersUdU9Eub9VKMeta } from "/opt/buildhome/repo/pages/publishers.vue?macro=true";
export default [
  {
    name: "advertisers",
    path: "/advertisers",
    meta: advertisersY5zffW22E9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/advertisers.vue").then(m => m.default || m)
  },
  {
    name: "analytics",
    path: "/analytics",
    meta: analyticsFiKBOYLNmQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/analytics.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/opt/buildhome/repo/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "publishers",
    path: "/publishers",
    meta: publishersUdU9Eub9VKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publishers.vue").then(m => m.default || m)
  }
]